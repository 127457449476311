import { fetchUtils } from 'react-admin';
import restServerProvider from 'ra-data-json-server';

const servicesHost = 'https://apip.shadowdog.eu';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth-token');
    // add your own headers here
    options.headers.set('auth-token', token);

    return fetchUtils.fetchJson(url, options);
};

const dataRestProvider = restServerProvider(servicesHost, httpClient);

let formData;

function getElements(resource, params) {
    formData = new FormData();
    switch (resource) {
        case 'works': {
            formData.append('name', params.data.name);
            formData.append('description', params.data.description);
            formData.append('type', params.data.type);
            formData.append('link', params.data.link);
            formData.append('category', params.data.category);

            if (params.data.mainImg) {
                formData.append('mainImg', params.data.mainImg.rawFile);
            }

            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }
        case 'category': {
            formData.append('name', params.data.name);

            if (params.data.mainImg) {
                formData.append('mainImg', params.data.mainImg.rawFile);
            }
            break;
        }
        default: {
            break;
        }
    }
}

const myDataProvider = {
    ...dataRestProvider,
    create: (resource, params) => {
        getElements(resource, params);

        return httpClient(`${servicesHost}/${resource}`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    update: (resource, params) => {
        getElements(resource, params);

        return httpClient(`${servicesHost}/${resource}/${params.id}`, {
            method: 'PUT',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
};

export default myDataProvider;
