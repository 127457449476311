import * as React from 'react';
import {
    ImageField,
    TextField,
    Datagrid,
    List,
    DeleteButton,
} from 'react-admin';

export const ImagesList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='subType' label='Kategoria' />
            <TextField source='subID' label='ID kategorii' />
            <TextField source='field' label='Typ zdjęcia' />
            <ImageField source='path' label='Zdjęcie' />
            <DeleteButton basePath='/files' label='Usuń' />
        </Datagrid>
    </List>
);
