import React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
    ImageInput,
    ImageField,
    TextInput,
    Edit,
    SimpleForm,
    NumberField,
} from 'react-admin';
import { Box } from '@material-ui/core';

export const CategoryList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <NumberField source='id' label='ID' />
            <TextField source='name' label='Nazwa' />
            <EditButton basePath='/category' label='Edytuj' />
            <DeleteButton basePath='/category' label='Usuń' />
        </Datagrid>
    </List>
);

export const CategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <TextInput source='name' label='Nazwa' fullWidth />
                </Box>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='mainImg'
                        label='Zdjęcie'
                        accept='image/*'
                        fullWidth
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <TextInput source='name' label='Nazwa' fullWidth />
                </Box>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='mainImg'
                        label='Zdjęcie'
                        accept='image/*'
                        fullWidth
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);
