import React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
    ImageInput,
    ImageField,
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';

export const WorksList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='name' label='Tytuł' />
            <TextField source='type' label='Typ' />
            <TextField source='category' label='Kategoria' />
            <EditButton basePath='/works' label='Edytuj' />
            <DeleteButton basePath='/works' label='Usuń' />
        </Datagrid>
    </List>
);

export const WorksEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <TextInput source='name' label='Nazwa' fullWidth />
                </Box>
                <Box flex='1' mx='10px'>
                    <TextInput source='link' label='Link do YT' fullWidth />
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <SelectInput
                        source='type'
                        choices={[
                            { id: 'card', name: 'Karta' },
                            { id: 'link', name: 'Link' },
                        ]}
                        fullWidth
                    />
                </Box>
                <Box flex='1' mx='10px'>
                    <ReferenceInput
                        label='Kategoria'
                        source='category'
                        reference='category'
                    >
                        <SelectInput optionText='name' optionValue='path' />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <RichTextInput
                        source='description'
                        label='Opis'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='mainImg'
                        label='Zdjęcie'
                        accept='image/*'
                        fullWidth
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='gallery'
                        label='Galeria'
                        accept='image/*'
                        fullWidth
                        multiple
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export const WorksCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <TextInput source='name' label='Nazwa' fullWidth />
                </Box>
                <Box flex='1' mx='10px'>
                    <TextInput source='link' label='Link do YT' fullWidth />
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <SelectInput
                        source='type'
                        choices={[
                            { id: 'card', name: 'Karta' },
                            { id: 'link', name: 'Link' },
                        ]}
                        fullWidth
                    />
                </Box>
                <Box flex='1' mx='10px'>
                    <ReferenceInput
                        label='Kategoria'
                        source='category'
                        reference='category'
                    >
                        <SelectInput optionText='name' optionValue='path' />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <RichTextInput
                        source='description'
                        label='Opis'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box display='flex' fullWidth>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='mainImg'
                        label='Zdjęcie'
                        accept='image/*'
                        fullWidth
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
                <Box flex='1' mx='10px'>
                    <ImageInput
                        source='gallery'
                        label='Galeria'
                        accept='image/*'
                        fullWidth
                        multiple
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);
