import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import getDataProvider from './Config/dataProvider';
import getAuthProvider from './Config/authProvider';

//Import Tabs
import Dashboard from './Components/Dashboard/Dashboard';
import { WorksList, WorksCreate, WorksEdit } from './Components/Works/Works';
import {
    CategoryList,
    CategoryCreate,
    CategoryEdit,
} from './Components/Category/Category';
import { ImagesList } from './Components/Images/Images';

const App = () => (
    <Admin
        dashboard={Dashboard}
        title='Art Shadow Dog'
        dataProvider={getDataProvider}
        authProvider={getAuthProvider}
    >
        <Resource
            name='works'
            list={WorksList}
            edit={WorksEdit}
            create={WorksCreate}
        />
        <Resource
            name='category'
            list={CategoryList}
            edit={CategoryEdit}
            create={CategoryCreate}
        />
        <Resource name='files' list={ImagesList} />
    </Admin>
);

export default App;
