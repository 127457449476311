import axios from 'axios';
import decodeJwt from 'jwt-decode';
const host = 'https://apip.shadowdog.eu/users/login';

const authProvider = {
    login: ({ username, password }) => {
        return axios
            .post(
                host,
                {
                    email: username,
                    password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                }
            )
            .then((res) => {
                localStorage.setItem('auth-token', res.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth-token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('auth-token')
            ? Promise.resolve()
            : Promise.reject(),
    logout: () => {
        localStorage.removeItem('auth-token');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id, name, lastname, email } = decodeJwt(
                localStorage.getItem('auth-token')
            );
            return Promise.resolve({ id, name, lastname, email });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;
